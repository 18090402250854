import axios, { AxiosResponse } from 'axios';
import { User } from '../interfaces';
import { Config } from '../Config';

export const registerUser = (user: User) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const query = 'username=' + user.username + '&password=' + encodeURIComponent(user.password ?? '');
    const url = `${Config.AUTH_SERVER}/home/newuser?` + query;
    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const keepAlive = (user: User) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const url = `${Config.AUTH_SERVER}/home/keepalive?username=` + user.username;
    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const forgotPassword = (user: User) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const query = 'username=' + user.username;
    const url = `${Config.AUTH_SERVER}/home/forgotpassword?` + query;
    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const authUser = (user: User) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const query = 'username=' + user.username + '&password=' + encodeURIComponent(user.password ?? '');
    const url = `${Config.AUTH_SERVER}/home/login?` + query;
    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const userOrganizationDetails = (user: User) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const query = 'username=' + user.username + '&password=' + encodeURIComponent(user.password ?? '');
    const url = `${Config.AUTH_SERVER}/home/userorganizationdetails?` + query;
    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const acceptTerms = (user: User) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const query = 'username=' + user.username;
    const url = `${Config.API_SERVER}/api/terms/acceptterms?` + query;
    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
