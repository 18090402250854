import {
  IonCol,
  IonContent,
  IonPage,
  IonRouterLink,
  IonRow,
  IonIcon,
} from "@ionic/react";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { icons, images } from "../../../assets";
import DesktopContainer from "../../../components/desktop-container";
import DesktopHeader from "../../../components/desktop-header";
import Header from "../../../components/header";
import SideMenu from "../../../components/side-menu";
import SubPageHeading from "../../../components/sub-page-heading";
import { AppScreens } from "../../../enums";

import { RootState } from "../../../redux/store";
import {
  hideSideMenuDisabledItems,
  setScreen,
  showLoader,
} from "../../../redux/ui/uiSlice";
import moment from "moment";
import { getImage, modelClass } from "../../../api/mapo";
import {
  chevronBackCircleSharp,
  chevronBackOutline,
  chevronForwardCircleSharp,
  eyeSharp,
  thumbsUpSharp,
} from "ionicons/icons";
import { useHistory } from "react-router-dom";
import { ModelClass } from "../../../interfaces/modelClass";
import { useMediaQuery } from "react-responsive";
import GlobalGrid from "../../../components/globalGridMain/globalGrid";

interface ModelDataType {
  engineCode: null | string;
  groupCode: number;
  introDate: string;
  make: string;
  variant: string;
}
//
const OverViewDetail: React.FC = () => {
  const uiState = useSelector((state: RootState) => state.ui);
  const dispatch = useDispatch();
  const history = useHistory();
  const sessionState = useSelector((state: RootState) => state.session);
  const [localStorageLength, setLocalStorageLength] = useState(0);

  useEffect(() => {
    const storedCart = localStorage.getItem("cart");
    if (storedCart) {
      const cartItems = JSON.parse(storedCart);
      setLocalStorageLength(cartItems.length);
    }
  }, [localStorageLength, localStorage.getItem("cart")]);


  const onBackPress = useCallback(() => {
    history.push("/home");
  }, [dispatch, uiState]);

  const onDetailedSpecs = useCallback(() => {
    dispatch(setScreen(AppScreens.DetailSpecs));
    history.push("/DetailSpecs");
  }, [dispatch]);

  const onServiceCosts = useCallback(() => {
    //dispatch(setScreen(AppScreens.ServicePlan));
    history.push("/ServicePlan");
  }, [dispatch]);

  const onMaintananceCosts = useCallback(() => {
    // dispatch(setScreen(AppScreens.MaintenancePlan));
    history.push("/MaintenancePlan");
  }, [dispatch]);

  const onTCO = useCallback(() => {
    // dispatch(setScreen(AppScreens.TotalCostOwner));
    history.push("/TotalCostOwner");
  }, [dispatch]);

  const onPartsBasket = useCallback(() => {
    // dispatch(setScreen(AppScreens.Basket));
    history.push("/Basket");
  }, [dispatch]);

  const on10KRates = useCallback(() => {
    // dispatch(setScreen(AppScreens.RatesTenK));
    history.push("/RatesTenK");
  }, [dispatch]);

  const onCompetitors = useCallback(() => {
    //dispatch(setScreen(AppScreens.Competitor));
    history.push("/Competitor");
  }, [dispatch]);

  const onReviews = useCallback(() => {
    dispatch(setScreen(AppScreens.Reviews));
  }, [dispatch]);

  const onResidualValue = useCallback(() => {
    //dispatch(setScreen(AppScreens.ResellValue));
    history.push("/ResellValue");
  }, [dispatch]);

  const onFinanceCalculator = useCallback(() => {
    //dispatch(setScreen(AppScreens.FinanceCalculate));
    history.push("/FinanceCalculate");
  }, [dispatch]);

  const onPreowned = useCallback(() => {
    dispatch(setScreen(AppScreens.Preowned));
  }, [dispatch]);

  const OnBackPress = () => {
    history.push("/VehicleLanding");
  };

  //The Date for the vehicle in modelClass
  const [ModelDate, setModelDate] = useState<ModelDataType[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Check if uiState.selectedVehicle is defined
        if (uiState.selectedVehicle) {
          const modelClassResponse = await modelClass(
            uiState.selectedVehicle.g_id,
            uiState.selectedVehicle.m_id,
            uiState.selectedVehicle.v_id
          );

          // Check if the response status is 200
          if (modelClassResponse.status === 200) {
            const result = modelClassResponse.data.result[0]; // Assuming result is an array with at least one element

            // Access and log specific properties

            const data = modelClassResponse.data;

            // Set the fetched data to ModelDate state
            setModelDate(modelClassResponse.data.result || []);
          } else {
            console.warn("Model class response status is not 200");
          }
        } else {
          console.warn("uiState.selectedVehicle is undefined");
        }
      } catch (error) {
        console.error(error);
        // Handle error as needed
      }
    };

    fetchData();
  }, [uiState.selectedVehicle, modelClass]);

    //To save the selectedVehicle in the cart
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState("");

    
  const showMessageWithTimeout = (text: any) => {
    setMessage(text);
    setShowMessage(true);
  };
  

  const handleSaveSelectedVehicleToCart = () => {
    // if (uiState.selectedVehicle) {
    //   // Retrieve existing cart data from localStorage
    //   const storedCartString = localStorage.getItem("cart");
    //   const existingCart = storedCartString ? JSON.parse(storedCartString) : [];

    //   // Check if the item with the same 'gmv' is already in the cart
    //   const isDuplicate = existingCart.some(
    //     (item: any) => item.variant === uiState.selectedVehicle?.variant
    //   );

    //   if (!isDuplicate) {
    //     // Add the current model to the cart
    //     existingCart.push(uiState.selectedVehicle);

    //     localStorage.setItem("cart", JSON.stringify(existingCart));
    //     // Show the "Item added to cart" message
    //     showMessageWithTimeout("Model added to cart");
    //   } else {
    //     // Show the "Item already in cart" message
    //     showMessageWithTimeout("Model already in cart");
    //   }
    // }
    history.push("/Cart");
  };
 

  return (
    <>
      <GlobalGrid
        currentPageTitle="OVERVIEW"
        closeCurrentPage={() => onBackPress()}
      >
        <div className="innerContent">
          <div className="headHolder HeaderStick">
            <IonRow id="selectedVehicleGlobal">
              {uiState.selectedVehicle ? (
                <>
                  <IonCol
                    sizeXs="6"
                    sizeMd="4"
                    sizeLg="3"
                    id="gridOverviewImageCont"
                  >
                    <img
                      id="gridOverviewImage"
                      src={getImage(
                        uiState.selectedVehicle.g_id.toString(),
                        uiState.selectedVehicle.m_id.toString(),
                        uiState.selectedVehicle.v_id.toString()
                      )}
                      alt=""
                    />{" "}
                  </IonCol>

                  <IonCol
                    sizeXs="6"
                    sizeMd="8"
                    sizeLg="9"
                    id="gridOverviewDetails"
                  >
                    <h1>{uiState.selectedVehicle.make}</h1>
                    <h1>{uiState.selectedVehicle.model}</h1>
                    <h1 id="gridOverviewVariant">
                      {Array.isArray(ModelDate) &&
                        ModelDate.map((details, index) => (
                          <>{details.variant}</>
                        ))}
                    </h1>
                    <span id="overViewCluster">
                      {Array.isArray(ModelDate) &&
                        ModelDate.map((details, index) => (
                          <h1 key={index}>
                            MY{" "}
                            {moment(new Date(details.introDate ?? "")).format(
                              "YYYY"
                            )}
                          </h1>
                        ))}
                      <h1>
                        G{uiState.selectedVehicle.g_id.toString()}M
                        {uiState.selectedVehicle.m_id.toString()}V
                        {uiState.selectedVehicle.v_id.toString()}
                      </h1>
                      {/* <h1>{uiState.selectedVehicle.variant_id}</h1>{" "} */}
                    </span>
                  </IonCol>
                </>
              ) : null}
            </IonRow>
          </div>
        </div>
        <div className="detailContent">
          <IonRow onClick={onDetailedSpecs}>
            <IonCol size="11" className="gridOverviewMenuRows">
              <img src={chevronForwardCircleSharp} alt="" />
              <p>DETAILED SPECS</p>
            </IonCol>
            {/* <IonCol className="overviewLink">DETAILED SPECS</IonCol> */}
          </IonRow>

          <IonRow onClick={onCompetitors}>
            <IonCol size="11" className="gridOverviewMenuRows">
              <img src={chevronForwardCircleSharp} alt="" />
              <p>COMPETITORS</p>
            </IonCol>
          </IonRow>

          <IonRow onClick={onFinanceCalculator}>
            <IonCol size="11" className="gridOverviewMenuRows">
              <img
                src={chevronForwardCircleSharp}
                alt=""
                className="chevronBlac"
              />
              <p>FINANCE CALCULATOR</p>
            </IonCol>
          </IonRow>

          <IonRow onClick={onServiceCosts}>
            <IonCol size="11" className="gridOverviewMenuRow">
              <img src={chevronForwardCircleSharp} alt="" />
              <p style={{ color: "grey" }}>SERVICE COSTS</p>
            </IonCol>
          </IonRow>
          <IonRow onClick={onMaintananceCosts}>
            <IonCol size="11" className="gridOverviewMenuRow">
              <img src={chevronForwardCircleSharp} alt="" />
              <p style={{ color: "grey" }}>MAINTENANCE COSTS</p>
            </IonCol>
          </IonRow>
          <IonRow onClick={onTCO}>
            <IonCol size="11" className="gridOverviewMenuRow">
              <img src={chevronForwardCircleSharp} alt="" />
              <p style={{ color: "grey" }}>TCO</p>
            </IonCol>
          </IonRow>
          <IonRow onClick={onPartsBasket}>
            <IonCol size="11" className="gridOverviewMenuRow">
              <img src={chevronForwardCircleSharp} alt="" />
              <p style={{ color: "grey" }}>PARTS BASKET</p>
            </IonCol>
          </IonRow>
          <IonRow onClick={on10KRates}>
            <IonCol size="11" className="gridOverviewMenuRow">
              <img src={chevronForwardCircleSharp} alt="" />

              <p style={{ color: "grey" }}> 10K RATES TABLE</p>
            </IonCol>
          </IonRow>
          <IonRow onClick={onResidualValue}>
            <IonCol size="11" className="gridOverviewMenuRows">
              <img src={chevronForwardCircleSharp} alt="" />
              <p>RESIDUAL VALUE</p>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol className="LimitedView">
              <button style={{
                background:"white"
              }}>
                <IonIcon id="cartIcon" src={eyeSharp} />
              </button>
              <button  onClick={handleSaveSelectedVehicleToCart}>
               RENEW SUBCRIPTION 
                <img id="icon" src={icons.WhiteCartIcon} />
              </button>
            </IonCol>
          </IonRow>
        </div>
        {showMessage ? (
          <div className="addToCartAlertCont">
            <div className="thumb">
              <IonIcon src={thumbsUpSharp}></IonIcon>
            </div>
            <p>{message}</p>
          </div>
        ) : null}
      </GlobalGrid>
    </>
  );
};

export default OverViewDetail;
