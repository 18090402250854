import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ExpiryDate, Oem, Theme, User } from '../../interfaces';

export type Session = {
  user: User | undefined;
  branded: boolean;
  theme?: Theme | undefined;
  termsAcceped?: boolean;
  selectedOem?: Oem;
  userExpiryDate?: ExpiryDate;
};

const initialState: Session = {
  user: undefined,
  branded: true,
  theme: undefined,
  termsAcceped: false,
  selectedOem: undefined,
  userExpiryDate: undefined,
};

const sessionSlice = createSlice({
  name: 'session',
  initialState: initialState,
  reducers: {
    sessionLogin: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
    sessionLogout: (state) => {
      state.user = undefined;
      state.branded = false;
      state.termsAcceped = false;
      state.theme = undefined;
      state.userExpiryDate = undefined
    },
    setBranded: (state, action: PayloadAction<boolean>) => {
      state.branded = action.payload;
    },
    setTheme: (state, action: PayloadAction<Theme>) => {
      state.theme = action.payload;
    },
    setTermsAccepted: (state, action: PayloadAction<boolean>) => {
      state.termsAcceped = action.payload;
    },
    setSelectedOem: (state, action: PayloadAction<Oem>) => {
      state.selectedOem = action.payload;
    },
    setExpiryDate: (state, action: PayloadAction<any>) => {
      state.userExpiryDate = action.payload
    }
  },
});

export const {
  sessionLogin,
  sessionLogout,
  setBranded,
  setTheme,
  setTermsAccepted,
  setSelectedOem,
  setExpiryDate,
} = sessionSlice.actions;
export default sessionSlice.reducer;
